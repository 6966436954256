export default {
	reload () {
		window.location.reload()
	},
	listenArrowKey (e) {
		const currentElement = document.activeElement
		const currentParent = currentElement?.closest('.focus-container')

		if (e.key === 'ArrowRight') {
			const nextParent = currentParent?.nextElementSibling || currentParent?.parentNode.closest('.focus-container')?.nextElementSibling
			const nextElement = nextParent?.getElementsByClassName('focus-element')[0]

			nextElement?.focus()
		} else if (e.key === 'ArrowLeft') {
			const previousParent = currentParent?.previousElementSibling || currentParent?.parentNode.closest('.focus-container')?.previousElementSibling || currentParent?.parentNode.closest('.focus-container')?.parentNode.closest('.focus-container')?.previousElementSibling
			const previousElement = previousParent?.getElementsByClassName('focus-element')[0]

			previousElement?.focus()
		}
	},
	focus (refName, selector) {
		if (refName && this.$refs[refName]) {
			this.$refs[refName].focus()
		} else if (selector) {
			document.querySelector(selector)?.focus()
		}
	},
	setTabsShortcuts () {
		document.getElementsByClassName('tab').forEach((t, i) => {
			t.childNodes[4].innerHTML = `Alt + ${i + 1}`
		})
	},
	getUniqueId (id = null, date = null) {
		date = date || new Date()

		const appVersionNumber = this.store?.getters.appVersionNumber || this.$store.getters.appVersionNumber

		if (appVersionNumber >= 3849) {
			return id ? id.toString() : `${date.valueOf()}.${date.getMilliseconds()}`
		} else {
			return id ? parseInt(id) : date.valueOf()
		}
	},
	checkObjectKey (keys) {
		let object = this.$store.state.settings

		keys.every((key) => {
			object = object ? object[key] : undefined

			return typeof object !== 'undefined'
		})

			return typeof object !== 'undefined' ? object : true
	},
	getDataURL (url) {
		return new Promise((resolve) => {
			const image = new Image()

			image.onload = function () {
				const canvas = document.createElement('canvas')

				canvas.width = this.naturalWidth
				canvas.height = this.naturalHeight
				canvas.getContext('2d').drawImage(this, 0, 0)
				resolve(canvas.toDataURL('image/png'))
			}

			image.src = `/api/image?r=${url}`
		})
	},
	getPriceFromBarcode (barcode) {
		if (barcode.length === 13) {
			return {
				barcode: barcode.substr(0, 7),
				price: parseFloat(barcode.substr(-6, 3) + '.' + barcode.substr(-3, 2))
			}
		} else if (barcode.length === 12) {
			return {
				barcode: barcode.substr(0, 7),
				price: parseFloat(barcode.substr(-5, 2) + '.' + barcode.substr(-3, 2))
			}
		}

		return null
	},
	getUpdatedAtDateTime (time) {
		if (!time) { return null }

		const day = new Date(time)

		day.setHours(day.getHours() - 1)

		return new Date(day.getTime() + day.getTimezoneOffset() * 60000)
			.toISOString().slice(0, 19).replace('T', ' ')
	},
	applyMultiPricing (variation, priceCategoryId) {
		const priceCategory = variation.price_category.find((priceCategory) => {
			return (priceCategory.category_id === priceCategoryId && (
				priceCategory.config === 'local' || priceCategory.type === 'item'
			)) || (
				(
					priceCategory.custom_attributes && priceCategory.custom_attributes.applicable_time_period === 'range' && this.$moment().isBetween(
						this.$moment(`${priceCategory.start_date} ${priceCategory.start_time}`),
						this.$moment(`${priceCategory.end_date} ${priceCategory.end_time}`)
					)
				) || (
					priceCategory.custom_attributes && priceCategory.custom_attributes.applicable_time_period === 'days' &&
					priceCategory.custom_attributes.days_of_week.includes(this.$moment().format('dddd').toLowerCase()) &&
					this.$moment().isBetween(this.$moment(priceCategory.start_time, 'hh:mm:ss'), this.$moment(priceCategory.end_time, 'hh:mm:ss'))
				)
			)
		})

		if (priceCategory) {
			variation.price_category_id = priceCategory.category_id || priceCategory.id
			variation.tax = priceCategory.tax

			if (priceCategory.price_type === 'percentage') {
				variation.price = variation.price +
					((priceCategory.price_value / 100) * variation.price)
			} else if (priceCategory.price_type === 'fixed') {
				variation.price += priceCategory.price_value
			}

			if (variation.price < 0) {
				variation.price = 0
			}
		}

		if (variation.batch) {
			variation.batch = variation.batch.map((batch) => {
				if (priceCategory.price_type === 'percentage') {
					batch.price =
					batch.price + (priceCategory.price_value / 100) * batch.price
				} else if (priceCategory.price_type === 'fixed') {
					batch.price += priceCategory.price_value
				}

				if (batch.price < 0) {
					batch.price = 0
				}

				return batch
			})
		}

		return variation
	},
	validateConditions (price, conditions) {
		for (const key in conditions) {
			// eslint-disable-next-line
			if (Object.prototype.hasOwnProperty.call(price, key) && conditions[key]?.length && !eval(conditions[key].map((condition) => {
				return `${price[key]} ${condition.operator} ${condition.value}`
			}).join(' && '))) {
				return false
			}
		}

		return true
	},
	groupBy (xs, key) {
		return xs.reduce((rv, x) => {
			(rv[x[key]] = rv[x[key]] || []).push(x)

			return rv
		}, {})
	},
	toFixed (num, fixed) {
		const arr = num.toString().match(new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?'))

		return arr?.length ? arr[0] : num
	},
	sleep (ms) {
		return new Promise(resolve => setTimeout(resolve, ms))
	},
	getDomain () {
		return `${window.location.protocol}//${window.location.hostname}${
			window.location.port ? (':' + window.location.port) : ''
		}`
	},
	convertCase (from, to, str) {
		switch (`${from}-${to}`) {
			case 'kebab-camel':
				str = str.replace(/-([a-z])/g, g => g[1].toUpperCase())
				break
			case 'kebab-snake':
				str = str.replace(/-/g, '_')
				break
			default:
				break
		}

		return str
	},
	getUnitMeasureType (unit) {
		return typeof unit === 'string' ? unit : unit?.short_name
	},
	getUnitDecimalPlaces (unit) {
		let decimalPlaces = 0

		unit = this.getUnitMeasureType(unit)

		switch (unit) {
			case 'count':
				decimalPlaces = 0
				break
			case 'weight':
			case 'volume':
			case 'length':
				decimalPlaces = 3
				break
		}

		return decimalPlaces
	},
	getWeight (setWeight) {
		const options = this.$store.getters.appVersionNumber >= 4126
			? this.$store.state.weighingScale
			: this.$store.state.weighingScale.serialPortOptions

		window.getWeight = setWeight
		this.$bridge.getWeight(
			this.$store.state.bridgeName === 'ANDROID'
				? this.objToJson(options)
				: options
		)
	},
	evenly (numerator, denominator) {
		const pow10 = this.$currency.transformNumber(Math.pow(10, this.$currency.getCurrencyDecimalPlaces()))

		numerator = this.$currency.transformNumber(numerator * pow10)

		const divide = Math.floor(numerator / denominator)
		const remain = this.$currency.transformNumber(numerator - divide * denominator)
		const result = []
		let cnt = 0

		while (++cnt && cnt <= denominator) {
			result.push(this.$currency.transformNumber((cnt <= remain ? divide + 1 : divide) / pow10))
		}

		return result
	},
	filterItem (items, item, returnType) {
		const itemModifiers = item.groups
			? item.groups.filter(g => g.type === 'modifier').map(m => m.group_item_variation_id)
			: []
		const index = items.findIndex((i) => {
			const iModifiers = i.groups
				? i.groups.filter(g => g.type === 'modifier').map(m => m.group_item_variation_id)
				: []
			const iDynamicDiscount = Array.isArray(i.item_discount) && i.item_discount.find(i => i.entity === 'dynamic')
			const itemDynamicDiscount = Array.isArray(item.item_discount) && item.item_discount.find(i => i.entity === 'dynamic')
			const dynamicDiscountFlag = (!itemDynamicDiscount && !iDynamicDiscount) || (iDynamicDiscount && itemDynamicDiscount && iDynamicDiscount.get_discount_type === itemDynamicDiscount.get_discount_type &&
				iDynamicDiscount.get_discount_value === itemDynamicDiscount.get_discount_value)

			return (i.id || i.variation_id) === (item.id || item.variation_id) && i.batch_id === (item.batch_id || null) &&
				i.price === item.price && itemModifiers.filter(m => !iModifiers.includes(m)).length === 0 && dynamicDiscountFlag &&
				(!i.custom_attributes?.gift_card || i.custom_attributes.gift_card.id === item.custom_attributes?.gift_card?.id)
		})

		if (returnType === 'index') {
			return index
		} else {
			return index !== -1 ? items[index] : null
		}
	},
	async addOrderItemsToCart (order, newItems = [], reset = true) {
		if (reset) {
			this.$store.commit('resetCart')
		}

		const cartItems = []
		const itemDiscounts = this.$store.getters.discounts('item')
		const categoryDiscounts = this.$store.getters.discounts('category')
		const charges = []

		if (order.charges?.length) {
			let dbCharges = await this.$bridge.getCharges(
				this.deviceId,
				this.$store.state.locationId,
				this.objToJson({ id: order.charges.map(c => c.id) })
			)

			dbCharges = (typeof dbCharges === 'string' ? JSON.parse(dbCharges) : dbCharges).data
			order.charges.forEach((orderCharge) => {
				const charge = dbCharges.find(dbCharge => dbCharge.id === orderCharge.id)

				if (charge?.category === 'dynamic_charge') {
					charges.push({
						...charge,
						value: orderCharge.value,
						tax: orderCharge.taxes.map(tax => ({
							id: tax.tax_id,
							name: tax.tax_name,
							rate: tax.tax_rate,
							inclusion_type: tax.inclusion_type
						})),
						name: orderCharge.name,
						type: orderCharge.type,
						category: orderCharge.slug,
						applicable_on: orderCharge.applicable_on
					})
				} else if (charge) {
					charges.push(charge)
				}
			})
		}

		if (order.tip) {
			this.$store.commit('setState', {
				key: 'tip',
				value: order.custom_attributes.tip || {
					type: 'fixed',
					value: order.tip
				}
			})
		}

		let dbItemVariations = await this.$bridge.getItemVariations(this.deviceId, this.objToJson({
			id: order.items.map(i => i.variation_id),
			offset: -1
		}))

		dbItemVariations = (typeof dbItemVariations === 'string' ? JSON.parse(dbItemVariations) : dbItemVariations)
		dbItemVariations = dbItemVariations.data || dbItemVariations
		order.items.forEach((item) => {
			let priceCategory = null
			const dbItemVariation = dbItemVariations.find(i => i.id === item.variation_id)
			let itemVariationDiscounts = itemDiscounts.filter((d) => {
				return d.discount_items.findIndex((i) => {
					return i.variation_id === item.variation_id && i.buy_condition_value
				}) !== -1
			})
			const itemCategoryDiscounts = categoryDiscounts.filter((d) => {
				d.entity = 'category'

				return d.discount_categories.findIndex((i) => {
					return i.category_id === item.category_id && i.buy_condition_value
				}) !== -1
			})

			itemVariationDiscounts = itemVariationDiscounts.concat(itemCategoryDiscounts)

			if (dbItemVariation) {
				if (order.price_category) {
					priceCategory = dbItemVariation.price_category.find(pc => pc.category_id === order.price_category.id)
				}

				cartItems.unshift({
					id: item.variation_id,
					item_id: item.item_id,
					category_id: item.category_id,
					inventory_id: item.inventory_id,
					batch_id: item.batch_id,
					brand_id: item.brand_id,
					kot_device_id: dbItemVariation.kot_device_id,
					item_code: item.item_code,
					name: item.variation_name,
					item_name: item.item_name,
					alternate_name: item.alternate_name,
					sku: dbItemVariation.sku,
					type: dbItemVariation.type,
					barcode: dbItemVariation.barcode,
					hsn: dbItemVariation.custom_attributes.hsn || '',
					unit_measure_type: item.unit_measure_type,
					mrp: parseFloat(dbItemVariation.custom_attributes.mrp || 0),
					price: parseFloat(item.price),
					quantity: item.quantity,
					tax: priceCategory
						? priceCategory.tax
						: (item.taxes.length
							? item.taxes.map((t) => {
								return {
									id: t.tax_id,
									name: t.tax_name,
									inclusion_type: item.tax_type === 'exclusive' ? 'additive' : 'inclusive',
									rate: t.tax_rate
								}
							})
							: dbItemVariation.tax),
					discount: item.discounts,
					item_discount: item.discounts.filter(d => Object.prototype.hasOwnProperty.call(d, 'type')),
					combo_discount: itemVariationDiscounts.reduce((discounts, discount) => {
						discount.discount_items.forEach((di) => {
							if (!(di.variation_id === item.variation_id && di.get_discount_quantity === 0 &&
								di.get_discount_type)) {
								discounts.push({
									...di,
									type: 'COMBO',
									id: discount.id,
									name: discount.name,
									entity: discount.entity
								})
							}
						})

						if (discount.discount_categories) {
							discount.discount_categories.forEach((di) => {
								if (di.category_id === item.category_id) {
									discounts.push({
										...di,
										type: 'COMBO',
										id: discount.id,
										name: discount.name,
										entity: discount.entity
									})
								}
							})
						}

						return discounts
					}, []),
					itemization_type: dbItemVariation.itemization_type || 'item',
					groups: item.groups,
					notes: item.notes,
					taxes: item.taxes,
					discountedAmount: item.discounted_amount,
					discountedTax: item.discounted_tax,
					discounts: item.discounts,
					custom_attributes: {
						...item.custom_attributes,
						...dbItemVariation.custom_attributes
					}
				})
			}
		})

		this.$store.commit('setCart', {
			orderType: !['open', 'closed', 'completed'].includes(order.status) ? order.custom_attributes.order_type : undefined,
			priceCategory: order.price_category,
			table: order.table,
			waiter: order.created_by,
			items: cartItems,
			additionalInfo: order.custom_attributes.labels?.reduce((info, label) => {
				info[label.label.toLowerCase().split(' ').join('-')] = label.value

				return info
			}, {}) || {},
			ignoredCharges: order.custom_attributes?.ignoredCharges || [],
			selectedDiscount: order.discounts[0],
			charges
		})

		if (newItems.length) {
			newItems.forEach(async (item) => {
				const cartItemIndex = this.filterItem(cartItems, item, 'index')

				if (cartItemIndex !== -1 && order.tables.filter(
					t => this.$store.state.selectedTables.findIndex(st => st.id === t.id) === -1
				).length === 0) {
					item.quantity += cartItems[cartItemIndex].quantity
				}

				if (item.item_discount.length) {
					cartItems.unshift(item)
					this.$store.commit('setCart', { items: cartItems })
				} else {
					await this.$store.dispatch('modifyCart', {
						item: {
							id: item.item_id,
							category_id: item.category_id,
							name: item.item_name,
							brand_id: item.brand_id
						},
						variation: {
							id: item.id,
							inventory_id: item.inventory_id,
							kot_device_id: item.kot_device_id,
							batch_id: item.batch_id,
							name: item.name,
							sku: item.sku,
							type: item.type,
							unit_measure_type: item.unit_measure_type,
							price: item.price,
							quantity: item.quantity,
							tax: item.tax,
							itemization_type: item.itemization_type,
							groups: item.groups,
							notes: item.notes,
							custom_attributes: {
								alternate_language: item.alternate_name,
								hsn: item.hsn,
								mrp: item.mrp
							}
						},
						triggerCalculation: false
					})
				}
			})
		}

		await this.$store.dispatch('cartCalculation')
	},
	getTableStatusColor (table) {
		const tableColors = {
			occupied: 'danger',
			free: 'success',
			billed: 'warning',
			blocked: 'secondary'
		}

		return table.status
			? tableColors[table.status]
			: (table.is_occupied ? 'danger' : 'success')
	},
	objToJson (obj) {
		// eslint-disable-next-line no-control-regex
		return obj ? JSON.stringify(obj).replace(/[\u0000-\u001F\u007F]/g, ' ') : '{}'
	},
	async formatOnlineOrder (onlineOrder) {
		const date = new Date()
		const onlineOrderId = this.getUniqueId(onlineOrder.id)
		const id = this.getUniqueId()
		const taxes = {}

		const getPaymentMethodBySlug = async (slug) => {
			const paymentMethod = await this.$bridge.getPaymentMethods(this.deviceId, this.objToJson({ slug }))

			return (
				typeof paymentMethod === 'string' ? JSON.parse(paymentMethod) : paymentMethod
			)[0] || null
		}

		let localItemVariations = []
		let paymentMethod = onlineOrder.payment.length
			? await getPaymentMethodBySlug(onlineOrder.payment[onlineOrder.payment.length - 1].method)
			: null
		let priceCategory = onlineOrder.merchant_price_category_id
			? await this.$bridge.getPriceCategories(
				this.deviceId,
				this.objToJson({ id: onlineOrder.merchant_price_category_id })
			)
			: null
		let tables = onlineOrder.tables || []
		let customer = await this.$store.dispatch('bridgeCall', {
			methodName: 'getCustomers',
			args: [this.deviceId, this.objToJson({ id: +onlineOrder.customer.phone.number })]
		})

		const taxTransformer = (tax) => {
			const t = {
				tax_id: tax.id || tax.name,
				tax_name: tax.name,
				tax_rate: tax.rate,
				tax_amount: tax.value,
				discounted_tax: tax.discounted_tax
			}

			if (taxes[t.tax_id]) {
				taxes[t.tax_id].tax_amount += t.tax_amount
				taxes[t.tax_id].discounted_tax += t.discounted_tax
			} else {
				taxes[t.tax_id] = t
			}

			return t
		}

		if (!paymentMethod) {
			paymentMethod = await getPaymentMethodBySlug('other')
		}

		if (priceCategory) {
			priceCategory = (typeof priceCategory === 'string' ? JSON.parse(priceCategory) : priceCategory)
			priceCategory = priceCategory.data ? priceCategory.data[0] : priceCategory[0]
		}

		tables = tables.map(table => ({
			...table,
			layout: this.objToJson(table.layout),
			custom_attributes: this.objToJson(table.custom_attributes),
			is_occupied: true,
			status: 'occupied'
		}))
		customer = (typeof customer === 'string' ? JSON.parse(customer) : customer).data[0]

		let dbOrder = await this.$store.dispatch('bridgeCall', {
			methodName: 'getOrders',
			args: [this.deviceId, this.objToJson({ id: onlineOrderId })]
		})

		dbOrder = (typeof dbOrder === 'string' ? JSON.parse(dbOrder) : dbOrder).data[0]
		localItemVariations = await this.$bridge.getItemVariations(this.deviceId, this.objToJson({
			id: onlineOrder.items.reduce((itemIds, item) => {
				const comboItemIds = item.groups
					? item.groups.reduce((comboItemIds, group) => {
						if (group.type === 'combo') {
							comboItemIds.push(group.item_id)
						}

						return comboItemIds
					}, [])
					: []

				itemIds.push(item.id)

				return itemIds.concat(comboItemIds)
			}, [])
		}))
		localItemVariations = (typeof localItemVariations === 'string'
			? JSON.parse(localItemVariations)
			: localItemVariations)
		localItemVariations = localItemVariations.data || localItemVariations

		const kotItems = []
		const localOrder = {
			id: onlineOrderId,
			merchant_id: onlineOrder.merchant_id,
			merchant: onlineOrder.merchant,
			device_id: this.deviceId,
			location_id: this.locationId,
			employee_id: this.employee.id,
			employee_shift_id: this.employeeShift.id,
			payment_method_id: paymentMethod ? paymentMethod.id : null,
			merchant_price_category_id: priceCategory?.id,
			price_category: priceCategory,
			customer_id: +onlineOrder.customer?.phone?.number || null,
			order_id: onlineOrderId,
			channel: onlineOrder.channel,
			tables,
			items: onlineOrder.items.map((item, index) => {
				const localItemVariation = localItemVariations.find(i => i.id === item.id)
				const modifiers = []
				const combos = []

				item.groups = item.groups
					? item.groups.map((g) => {
						const group = {
							...g,
							item_variation_name: g.item_name,
							item_variation_group_id: g.item_id,
							group_item_variation_id: g.group_id
						}

						if (g.type === 'modifier') {
							modifiers.push(group)
						} else {
							combos.push(group)
						}

						return group
					})
					: []

				if (localItemVariation) {
					if (['restaurant', 'qsr'].includes(this.merchant.businessType) && onlineOrder.interim_state === 'confirmed') {
						const kotId = this.getUniqueId(`${id}${kotItems.length + 1}`)

						if (combos.length) {
							combos.forEach((g, gIndex) => {
								const localComboItemVariation = localItemVariations.find(i => i.id === g.item_variation_group_id)

								if (localComboItemVariation) {
									kotItems.push({
										id: kotId + gIndex,
										kot_id: id,
										kot_device_id: g.device_id || null,
										employee_shift_id: this.employeeShift.id,
										order_id: onlineOrderId,
										item_variation_id: localComboItemVariation.id,
										item_code: item.item_code ? `${item.item_code}${gIndex}` : `${this.deviceId}${localComboItemVariation.id}${date.valueOf()}${gIndex}`,
										item_variation_name: `${item.name} - ${localComboItemVariation.name}`,
										item_variation_alternate_name: localComboItemVariation.custom_attributes.alternate_language || localComboItemVariation.alternate_name,
										unit_measure_type: localComboItemVariation.unit_measure_type,
										quantity: g.qty,
										price: (item.unit_price * g.qty) / combos.length,
										modifiers,
										notes: item.custom_attributes?.notes || '',
										status: 'pending',
										status_history: [{
											status: 'pending',
											created_at: date
										}],
										created_at: date,
										updated_at: date
									})
								}
							})
						} else {
							kotItems.push({
								id: kotId,
								kot_id: id,
								kot_device_id: item.device_id || null,
								employee_shift_id: this.employeeShift.id,
								order_id: onlineOrderId,
								item_variation_id: localItemVariation.id,
								item_code: item.item_code || `${this.deviceId}${item.id}${date.valueOf()}${index}`,
								item_variation_name: item.name,
								item_variation_alternate_name: localItemVariation.custom_attributes?.alternate_language || localItemVariation.alternate_name,
								unit_measure_type: localItemVariation.unit_measure_type,
								quantity: item.qty,
								price: item.unit_price * item.qty,
								modifiers,
								notes: item.custom_attributes?.notes || '',
								status: 'pending',
								status_history: [{
									status: 'pending',
									created_at: date
								}],
								created_at: date,
								updated_at: date
							})
						}
					}

					return {
						kot_device_id: item.device_id,
						item_id: localItemVariation.item_id,
						category_id: localItemVariation.item.category_id,
						item_variation_id: item.id,
						brand_id: localItemVariation.item.brand?.id || null,
						item_inventory_id: localItemVariation.inventory_id,
						item_code: item.item_code || `${this.deviceId}${item.id}${date.valueOf()}${index}`,
						item_name: localItemVariation.item.name,
						item_variation_name: item.name,
						alternate_name: item.alt_lang || localItemVariation.custom_attributes.alternate_language,
						itemization_type: 'item',
						unit_measure_type: localItemVariation.unit_measure_type,
						mrp: item.mrp,
						single_quantity_amount: item.unit_price,
						quantity: item.qty,
						groups: item.groups,
						notes: item.custom_attributes?.notes || '',
						sub_total: item.sub_total,
						total: item.total,
						gross_sales: item.sub_total,
						net_sales: item.net_sales,
						tax: item.tax,
						tax_type: localItemVariation.tax.length
							? localItemVariation.tax[0].inclusion_type === 'additive' ? 'exclusive' : 'inclusive'
							: '',
						tax_details: item.taxes.map(taxTransformer),
						discount: item.discount,
						discounted_amount: item.discount,
						discounted_tax: 0,
						discount_details: item.discounts || [],
						custom_attributes: item.custom_attributes || {}
					}
				} else {
					return false
				}
			}).filter(Boolean),
			order_payments: await onlineOrder.payment.reduce(async (arr, p) => {
				const paymentMethod = await getPaymentMethodBySlug(p.method)

				if (paymentMethod) {
					(await arr).push({
						payment_method_id: paymentMethod.id,
						employee_shift_id: this.employeeShift.id,
						order_id: onlineOrderId,
						slug: p.method,
						name: paymentMethod.name,
						amount: p.amount,
						updated_at: date
					})
				}

				return arr
			}, Promise.resolve([])),
			customers: [{
				id: +onlineOrder.customer.phone.number,
				merchant_id: onlineOrder.merchant_id,
				device_id: this.deviceId,
				customer_id: onlineOrder.customer.merchant_customer_id,
				first_name: onlineOrder.customer.name,
				phone: onlineOrder.customer.phone.number,
				email: onlineOrder.customer.email,
				address: onlineOrder.customer.address,
				credit_limit: customer?.credit_limit || 0,
				credit: customer?.credit || 0,
				debit: customer?.debit || 0,
				custom_attributes: customer?.custom_attributes || {},
				otp: onlineOrder.customer.otp,
				is_active: true,
				updated_at: date
			}],
			credits: dbOrder?.credits?.map(credit => ({
				...credit,
				employee_shift_id: credit.employee_shift.id,
				customer_id: credit.customer.id,
				payment_method: credit.payment_method.slug,
				custom_attributes: this.objToJson(credit.custom_attributes)
			})),
			status: tables.length ? 'pending' : 'draft',
			instructions: onlineOrder.instructions,
			receipt_code: (dbOrder || onlineOrder).receipt_code || this.receiptCode || await this.$store.dispatch('generateReceiptCode'),
			ref_code: (dbOrder || onlineOrder).ref_code || this.refCode || (await this.$store.dispatch('generateRefCode')).code,
			sub_total: onlineOrder.sub_total,
			inclusive_tax: 0,
			additive_tax: 0,
			total_tax: onlineOrder.total_tax,
			total_charge: onlineOrder.total_charge,
			charges: onlineOrder.charges.map((charge) => {
				return {
					...charge,
					taxes: charge.taxes?.map(taxTransformer) || []
				}
			}),
			taxes: Object.values(taxes),
			total_price: onlineOrder.total,
			total_discount: onlineOrder.discount,
			total_discounted_amount: onlineOrder.discounted_amount,
			total_discounted_tax: onlineOrder.discounted_tax,
			discounts: [],
			card_details: {},
			payment_note: '',
			amount_balance_returned: 0,
			total_amount: onlineOrder.total - (onlineOrder.due_amount || 0),
			due_amount: onlineOrder.due_amount,
			tip: onlineOrder.tip || 0,
			round_off_amount: onlineOrder.round_off_amount || 0,
			custom_attributes: {
				...onlineOrder.custom_attributes,
				other_payment_method: 'Online',
				channel_id: onlineOrder.channel_id,
				order_type: onlineOrder.order_type,
				platform: onlineOrder.custom_attributes.platform === 'store'
					? onlineOrder.custom_attributes.platform
					: onlineOrder.platform,
				instructions: onlineOrder.instructions,
				scheduled_at: onlineOrder.delivery_datetime
					? this.$moment.utc(onlineOrder.delivery_datetime).format('YYYY-MM-DD HH:mm:ss')
					: undefined
			},
			created_at: this.$moment.utc(onlineOrder.created_at).toDate(),
			updated_at: date,
			is_synced: true
		}

		if (kotItems.length) {
			localOrder.kot_items = kotItems
		}

		return localOrder
	},
	async acceptOnlineOrder (order) {
		const localOrder = await this.formatOnlineOrder(order)
		let dbOrder = await this.$store.dispatch('bridgeCall', {
			methodName: 'getOrders',
			args: [this.deviceId, this.objToJson({ id: localOrder.id })]
		})

		dbOrder = (typeof dbOrder === 'string' ? JSON.parse(dbOrder) : dbOrder).data[0]

		if (localOrder.kot_items) {
			let kotItemsToPrint = localOrder.kot_items

			if (dbOrder?.kot_items.length) {
				kotItemsToPrint = localOrder.kot_items.reduce((items, item) => {
					const orderItem = dbOrder.items.find(i => i.item_code === item.item_code)

					if (!orderItem || orderItem.quantity !== item.quantity) {
						item.quantity = orderItem ? Math.abs(orderItem.quantity - item.quantity) : item.quantity
						items.push(item)
					}

					return items
				}, [])
				localOrder.kot_items = dbOrder.kot_items.concat(kotItemsToPrint)
			}

			window.printKot({
				...localOrder,
				items: this.groupBy(kotItemsToPrint, 'kot_device_id')
			})
		}

		if (order.platform !== 'advance' && !(Array.isArray(order.custom_attributes.tables) && order.custom_attributes.tables.length > 0)) {
			const orderToPrint = order.taxable_order
			? await this.formatOnlineOrder(order.taxable_order)
			: { ...localOrder }

			orderToPrint.reprint = false
			window.printOrder({
				...orderToPrint,
				payment_method: orderToPrint.order_payments[0],
				customer: orderToPrint.customers[0]
			})
		}

		this.saveOnlineOrder(localOrder, dbOrder)
	},
	async saveOnlineOrder (onlineOrder, dbOrder) {
		if (!dbOrder) {
			dbOrder = await this.$store.dispatch('bridgeCall', {
				methodName: 'getOrders',
				args: [this.deviceId, this.objToJson({ id: onlineOrder.id })]
			})
			dbOrder = (typeof dbOrder === 'string' ? JSON.parse(dbOrder) : dbOrder).data[0]
		}

		if (dbOrder) {
			delete onlineOrder.receipt_code

			if (onlineOrder.ref_code) {
				delete onlineOrder.ref_code
			}
		} else if (typeof this.$bridge.generateRefCode === 'undefined') {
			const generatedRefCode = await this.$store.dispatch('generateRefCode')

			await this.$store.dispatch('updateLastOrder', {
				orderCount: onlineOrder.receipt_code.substr(-4),
				refCode: onlineOrder.ref_code?.replace(generatedRefCode.prefix, ''),
				date: new Date()
			})
		}

		if (typeof this.$bridge.deleteData === 'function') {
			let data = await this.$store.dispatch('bridgeCall', {
				methodName: 'getData',
				args: ['OrderPaymentMethod', `order_id = "${onlineOrder.id}"`]
			})

			data = (typeof data === 'string' ? JSON.parse(data) : data).data
			await this.$store.dispatch('bridgeCall', {
				methodName: 'deleteData',
				args: ['OrderPaymentMethod', `order_id = "${onlineOrder.id}"`]
			})

			if (data.length) {
				onlineOrder.order_payments = onlineOrder.order_payments.reduce((payments, payment) => {
					const index = data.findIndex(p => p.order_id === payment.order_id && p.slug === payment.slug && p.amount === payment.amount && p.employee_shift_id !== payment.employee_shift_id)

					if (index === -1) {
						payments.push(payment)
					} else {
						payments.push(data[index])
					}

					return payments
				}, [])
			}
		}

		await this.$store.dispatch('bridgeCall', {
			methodName: 'customInsertOrUpdate',
			args: [
				'Order',
				this.deviceId,
				this.locationId,
				this.bridgeName === 'ANDROID' ? this.objToJson(onlineOrder) : onlineOrder
			]
		})
		await this.$store.dispatch('updateTableStatus', onlineOrder.tables?.map(t => t.id))

		if (onlineOrder.channel === 'pos_store' || (
			onlineOrder.custom_attributes.platform === 'advance' &&
			['completed', 'cancelled'].includes(onlineOrder.status) &&
			onlineOrder.order_payments.length > 1
		)) {
			const customer = {
				...onlineOrder.customers[0],
				address: this.objToJson(onlineOrder.customers[0].address),
				custom_attributes: this.objToJson(onlineOrder.customers[0].custom_attributes)
			}

			if (onlineOrder.custom_attributes.platform === 'advance') {
				customer.credit_limit = dbOrder.customer.credit_limit
				customer.credit = dbOrder.customer.credit
				customer.debit = dbOrder.customer.debit + onlineOrder.order_payments[onlineOrder.order_payments.length - 1].amount
			}

			this.$store.dispatch('bridgeCall', {
				methodName: 'insert',
				args: [
					'Customer',
					this.bridgeName === 'ANDROID' ? this.objToJson(customer) : customer,
					true
				]
			})
		}

		if (dbOrder?.status !== 'completed' && onlineOrder.status === 'completed') {
			this.$store.dispatch('updateDeviceSettings')

			if ((onlineOrder.custom_attributes.platform !== 'advance' || (
				onlineOrder.custom_attributes.platform === 'advance' && onlineOrder.order_payments.length > 1)) &&
				['cash', 'cod'].includes(onlineOrder?.order_payments[onlineOrder.order_payments.length - 1]?.slug)
			) {
				const event = {
					id: this.getUniqueId(null, onlineOrder.updated_at),
					merchant_id: onlineOrder.merchant_id,
					device_id: this.deviceId,
					employee_id: this.employee.id,
					employee_shift_id: this.employeeShift.id,
					cash_drawer_shift_id: this.cashDrawerShift.id,
					receipt_code: dbOrder?.receipt_code,
					amount: onlineOrder.custom_attributes.platform === 'advance'
						? onlineOrder.order_payments[onlineOrder.order_payments.length - 1].amount
						: dbOrder?.total_price,
					cash_via: 'sale',
					type: 'cash_in',
					is_synced: !!this.isMiniPlan,
					updated_at: onlineOrder.updated_at
				}

				this.$store.dispatch('bridgeCall', {
					methodName: 'insert',
					args: [
						'CashDrawerShiftEvent',
						this.bridgeName === 'ANDROID' ? this.objToJson(event) : event,
						true
					]
				})

				const syncData = {
					id: this.getUniqueId(),
					model_id: event.id,
					model_name: 'cash-drawer-shift-event',
					payload: this.objToJson({
						model_id: event.id,
						cash_drawer_shift_id: event.cash_drawer_shift_id,
						employee_id: event.employee_id,
						event_type: event.type,
						receipt_code: dbOrder?.receipt_code,
						cash_via: event.cash_via,
						event_money: event.amount,
						shift_event_code: `${this.deviceId}${event.id}`,
						employee_shift_code: this.employeeShift.shift_code
					})
				}

				this.$store.dispatch('bridgeCall', {
					methodName: 'insert',
					args: [
						'Sync',
						this.bridgeName === 'ANDROID' ? this.objToJson(syncData) : syncData,
						true
					]
				})
			}
		}
	}
}
