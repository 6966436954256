
	import { ValidationObserver, ValidationProvider } from 'vee-validate'

	let generatedSku = null

	export default {
		components: {
			ValidationObserver,
			ValidationProvider
		},
		data () {
			return {
				categories: [],
				brands: [],
				foodTypes: [
					{
						text: 'selectOption',
						value: ''
					},
					{
						text: 'veg',
						value: 'veg'
					},
					{
						text: 'non veg',
						value: 'nveg'
					},
					{
						text: 'egg',
						value: 'egg'
					}
				],
				unitMeasurementTypes: [],
				pricingTypes: [
					{
						text: 'selectOption',
						value: null
					},
					{
						text: 'fix',
						value: 'fixed',
						pluralizationIndex: 2
					},
					{
						text: 'variable',
						value: 'variable'
					},
					{
						text: 'multi',
						value: 'multi'
					}
				],
				taxes: [],
				itemId: null,
				itemName: null,
				category: null,
				variationId: null,
				inventoryId: null,
				variationName: null,
				foodType: '',
				unitMeasureType: null,
				sku: null,
				generatedSKU: null,
				barcode: null,
				alternateCode: null,
				pricingType: null,
				variationType: null,
				buyingPrice: null,
				mrp: null,
				description: null,
				price: null,
				minPrice: null,
				maxPrice: null,
				stock: 0,
				selectedTaxes: [],
				trackInventory: null,
				trackOnlineInventory: null,
				onlineAvailability: null,
				brand: null,
				offlineAvailability: true,
				itemAlternateName: null,
				variationAlternateName: null,
				variationTypes: [
					{
						text: 'selectOption',
						value: null
					},
					{
						text: 'sale',
						value: 'sale',
						pluralizationIndex: 1
					},
					{
						text: 'modifier',
						value: 'modifier'
					}
				],
				taxTypes: [
					{
						text: 'tax type',
						value: null
					},
					{
						text: 'include tax in item price',
						value: 'inclusive'
					},
					{
						text: 'add tax to item price',
						value: 'additive'
					}
				],
				newTax: {
					name: null,
					percentage: null,
					type: null
				},
				selectedPriceCategories: [],
				selectedPriceCategory: null,
				priceCategories: [],
				selectedPriceCategoryPriceValue: null,
				selectedPriceCategoryTax: []
			}
		},
		computed: {
			bridgeName () {
				return this.$store.state.bridgeName
			},
			appVersionNumber () {
				return this.$store.getters.appVersionNumber
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			item () {
				return this.$store.state.item
			},
			settings () {
				return this.$store.state.settings
			},
			isMiniPlan () {
				return this.$store.state.merchant.subscription.slug === 'mini'
			},
			taxCreationModal: {
				get () {
					return this.$store.state.taxCreationModal
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'taxCreationModal',
						value
					})
				}
			}
		},
		watch: {
			selectedTaxes (tax, prevtax) {
				if (!tax[tax.length - 1]?.id && prevtax.length < tax.length) {
					window.taxCreationModalCallback = this.taxCreationModalCallback
					this.selectedTaxes.pop()
					this.taxCreationModal.show = true
				}
			}
		},
		async beforeMount () {
			const item = JSON.parse(this.objToJson(this.item))

			if (item.id) {
				this.itemId = item.id
				this.itemName = item.name
				this.category = {
					...item.category,
					id: item.category.id,
					name: item.category.name
				}

				this.itemAlternateName = item.custom_attributes?.alternate_language || null

				if (item.variations.length) {
					this.variationId = item.variations[0].id
					this.inventoryId = item.variations[0].inventory_id
					this.variationName = item.variations[0].name
					this.foodType = item.variations[0].food_type
					this.unitMeasureType = item.variations[0].unit_measure_type
					this.sku = item.variations[0].sku
					this.barcode = item.variations[0].barcode
					this.alternateCode = item.variations[0].alternate_code
					this.pricingType = item.variations[0].pricing_type
					this.variationType = item.variations[0].type
					this.buyingPrice = item.variations[0].buying_price || item.variations[0].custom_attributes.buying_price
					this.mrp = item.variations[0].custom_attributes.mrp
					this.description = item.variations[0].description
					this.price = item.variations[0].original_price
					this.minPrice = item.variations[0].custom_attributes.min_price || this.$currency.getMinDecimalValue()
					this.maxPrice = item.variations[0].custom_attributes.max_price || false
					this.stock = item.variations[0].stock
					this.selectedTaxes = item.variations[0].tax
					this.trackInventory = Object.prototype.hasOwnProperty
						.call(item.variations[0].custom_attributes, 'track_inventory')
						? item.variations[0].custom_attributes.track_inventory
						: false
					this.onlineAvailability = Object.prototype.hasOwnProperty
						.call(item.variations[0].custom_attributes, this.appVersionNumber < 4023 ? 'is_available' : 'is_online_available')
						? this.appVersionNumber < 4023 ? item.variations[0].custom_attributes.is_available : item.variations[0].custom_attributes.is_online_available
						: false
					this.trackOnlineInventory = Object.prototype.hasOwnProperty
						.call(item.variations[0].custom_attributes, 'online_track_inventory')
						? item.variations[0].custom_attributes.online_track_inventory
						: false
					this.offlineAvailability = item.variations[0].is_available
					this.variationAlternateName = item.variations[0].custom_attributes?.alternate_language || null
					this.selectedPriceCategories = item.variations[0].price_category
				}

				if (item.brand) {
					this.brand = item.brand
				}
			} else {
				try {
					const response = await this.$axios.get('/api/pos/v2/counters', {
						params: { name: 'sku' }
					})

					this.sku = response.data.data.counters.sku
					generatedSku = response.data.data.counters.sku
				} catch (error) {
					console.error(error)
				}

				this.minPrice = ''
				this.maxPrice = ''
				this.trackInventory = false
				this.onlineAvailability = false
				this.trackOnlineInventory = false
				this.offlineAvailability = true
			}
		},
		methods: {
			async getData (fnName, varName, searchTerm) {
				const data = await this.$bridge[fnName](this.deviceId, this.objToJson({
					merchant_id: this.merchant.id,
					search_term: searchTerm,
					parent_category_id: []
				}))

				this[varName] = (typeof data === 'string' ? JSON.parse(data) : data).data

				if (varName === 'taxes' && this.isMiniPlan) {
					this[varName].push({
						name: 'create new tax',
						id: 0,
						type: null
					})
				}
			},
			async getPriceCategories () {
				let priceCategories = await this.$bridge.getPriceCategories(this.deviceId, this.objToJson({
					merchant_id: this.merchant.id,
					type: 'base'
				}))

				priceCategories = (typeof priceCategories === 'string' ? JSON.parse(priceCategories) : priceCategories)
				this.priceCategories = priceCategories.data || priceCategories
			},
			async addPriceCategory () {
				if (await this.$refs.priceCategoryValidator.validate()) {
					const date = new Date()

					const priceCategory = {
						...this.selectedPriceCategory,
						price_value: this.$currency.transformNumber(this.selectedPriceCategoryPriceValue - +this.price),
						category_id: this.selectedPriceCategory.id,
						tax: this.selectedPriceCategoryTax,
						updated_at: date
					}

					this.selectedPriceCategories.push(priceCategory)

					this.selectedPriceCategory = null
					this.selectedPriceCategoryTax = null
				}
			},
			selectPriceCategory () {
				if (this.selectedPriceCategory) {
					this.selectedPriceCategoryPriceValue = this.$currency.transformNumber(this.selectedPriceCategory.price_value + +this.price)
					this.selectedPriceCategoryTax = this.selectedPriceCategory.tax
				}
			},
			removePriceCategory (priceCategory) {
				this.selectedPriceCategories = this.selectedPriceCategories.filter(pc => pc.id !== priceCategory.id)
			},
			async getUnitMeasureTypes (searchTerm) {
				if (this.isMiniPlan) {
					this.unitMeasurementTypes = [
						{
							label: 'count',
							value: 'count',
							id: 1
						},
						{
							label: 'weight',
							value: 'weight',
							id: 2
						},
						{
							label: 'volume',
							value: 'volume',
							id: 3
						},
						{
							label: 'length',
							value: 'length',
							id: 4
						}
					]
				} else {
					try {
						const response = await this.$store.dispatch('getResource', {
							resource: 'merchant-masters',
							params: {
								master_type: 'unit_measure_type',
								query: searchTerm
							}
						})

						if (response?.code === 200) {
							this.unitMeasurementTypes = response.data.merchant_masters
						}
					} catch (err) {
						console.error(err)
					}
				}
			},
			async createItem () {
				if (!this.isMiniPlan && this.$offline.state === 'down') {
					return this.$swal({
						title: this.$t('offlineError.title'),
						text: this.$t('offlineError.text'),
						icon: 'error',
						button: this.$t('ok')
					})
				}

				if (await this.$refs.validator.validate()) {
					const date = new Date()

					if (this.$refs.submitBtn) {
						this.$refs.submitBtn.disabled = true
						this.$refs.submitBtn.classList.add('running')
					}

					const inventory = {
						id: this.inventoryId,
						location_id: this.locationId,
						pricing_type: this.pricingType,
						buying_price: parseFloat(this.buyingPrice) || 0,
						price: parseFloat(this.price),
						tax: this.selectedTaxes.map(tax => tax.id),
						mrp: parseFloat(this.mrp) || 0,
						is_active: true,
						is_available: true
					}

					if (!this.itemId) {
						if (this.trackInventory) {
							inventory.quantity_available = parseFloat(this.stock)
						}

						inventory.is_offline_available = true
					}

					if (typeof this.trackInventory === 'boolean') {
						inventory.track_inventory = this.trackInventory
					}

					if (typeof this.onlineAvailability === 'boolean') {
						inventory.is_available = this.onlineAvailability
					}

					if (typeof this.trackOnlineInventory === 'boolean') {
						inventory.online_track_inventory = this.trackOnlineInventory
					}

					if (typeof this.offlineAvailability === 'boolean' && this.appVersionNumber >= 4023) {
						inventory.is_offline_available = this.offlineAvailability
					}

					let response = null

					if (!this.isMiniPlan) {
						try {
							response = await this.$axios[this.itemId ? '$patch' : '$post']('/api/pos/item', {
								id: this.itemId,
								name: this.itemName,
								category_id: this.category.id,
								category_name: this.category?.name || this.category,
								brand_id: this.brand?.id || null,
								brand_name: this.brand?.name || this.brand,
								location_id: this.locationId,
								sku_generated: this.sku === generatedSku,
								is_active: true,
								custom_attributes: {
									alternate_language: this.itemAlternateName
								},
								variation: [{
									id: this.variationId,
									name: this.variationName,
									description: this.description,
									food_type: this.foodType,
									unit_measure_type: this.unitMeasureType.label,
									sku: this.sku,
									barcode: this.barcode,
									alternate_code: this.alternateCode,
									type: this.variationType || 'sale',
									is_active: true,
									custom_attributes: {
										alternate_language: this.variationAlternateName
									},
									inventory: [inventory]
								}]
							})
						} catch (err) {
							if (err.response && err.response.status === 422) {
								const errors = {}

								for (const e in err.response.data.data.errors) {
									errors[e.split('.').pop()] = [err.response.data.data.errors[e].replace('variation.0.', '')]
								}

								this.removeButtonLoading()

								return this.$refs.validator?.setErrors(errors)
							}
						}
					} else {
						let dbItemVariationsMatchSku = await this.$bridge.getItemVariations(this.deviceId, this.objToJson({
							sku: this.sku
						}))

						dbItemVariationsMatchSku = (typeof dbItemVariationsMatchSku === 'string' ? JSON.parse(dbItemVariationsMatchSku) : dbItemVariationsMatchSku)
						dbItemVariationsMatchSku = dbItemVariationsMatchSku.data || dbItemVariationsMatchSku
						dbItemVariationsMatchSku = dbItemVariationsMatchSku.filter(v => v.sku === this.sku && v.id !== this.variationId)

						if (dbItemVariationsMatchSku.length) {
							this.removeButtonLoading()

							return this.$refs.validator.setErrors({
								[this.$t('sku')]: [this.$t('skuAlreadyExists')]
							})
						}

						if (this.barcode) {
							let dbItemVariationsMatchBarcode = await this.$bridge.getItemVariations(this.deviceId, this.objToJson({
								barcode: this.barcode
							}))

							dbItemVariationsMatchBarcode = (typeof dbItemVariationsMatchBarcode === 'string' ? JSON.parse(dbItemVariationsMatchBarcode) : dbItemVariationsMatchBarcode)
							dbItemVariationsMatchBarcode = dbItemVariationsMatchBarcode.data || dbItemVariationsMatchBarcode
							dbItemVariationsMatchBarcode = dbItemVariationsMatchBarcode.filter(v => v.barcode === this.barcode && v.id !== this.variationId)

							if (dbItemVariationsMatchBarcode.length) {
								this.removeButtonLoading()

								return this.$refs.validator.setErrors({
									[this.$t('barcode')]: [this.$t('barcodeAlreadyExists')]
								})
							}
						}
					}

					if (this.isMiniPlan || response?.status === 'success') {
						let category = this.category
						let brand = this.brand

						if (typeof this.category === 'string' || this.isMiniPlan) {
							category = {
								id: response ? response.data.items.category.id : this.category?.id || date.valueOf(),
								name: response ? response.data.items.category.name : this.category?.name || this.category,
								item_count: this.itemId ? this.category.item_count : (this.category?.item_count || 0) + 1,
								device_id: this.deviceId,
								merchant_id: response ? response.data.items.merchantId : this.merchant.id,
								parent_category_id: this.category?.parent_category?.id || null,
								sub_category_count: this.category?.sub_category_count || 0,
								devices: [],
								custom_attributes: '{}',
								is_active: true,
								is_available: true,
								updated_at: date
							}

							await this.$bridge.insert(
								'Category',
								this.bridgeName === 'ANDROID' ? this.objToJson(category) : category,
								true
							)

							if (this.category.parent_category && (!this.item || (this.item?.category?.parent_category?.id !== this.category.parent_category.id))) {
								const parentCat = {
									...this.category.parent_category,
									sub_category_count: this.category.parent_category.sub_category_count + category.sub_category_count + 1,
									item_count: this.category.parent_category.item_count + category.item_count,
									custom_attributes: this.objToJson(this.category.parent_category.custom_attributes),
									updated_at: date
								}

								await this.$bridge.insert(
									'Category',
									this.bridgeName === 'ANDROID' ? this.objToJson(parentCat) : parentCat,
									true
								)
							}
						}

						if (typeof this.brand === 'string') {
							brand = {
								id: response ? response.data.items.brand.id : this.brand?.id || date.valueOf() + 1,
								name: response ? response.data.items.brand.name : this.brand?.name || this.brand,
								device_id: this.deviceId,
								merchant_id: response ? response.data.items.merchantId : this.merchant.id,
								alternate_name: '',
								custom_attributes: '{}',
								is_active: true,
								created_at: new Date(),
								updated_at: new Date()
							}

							await this.$bridge.insert(
								'Brand',
								this.bridgeName === 'ANDROID' ? this.objToJson(brand) : brand,
								true
							)
						}

						if (response?.data?.items) {
							await this.$bridge.customInsertOrUpdate(
								'Item',
								this.deviceId,
								this.locationId,
								this.bridgeName === 'ANDROID' ? this.objToJson([response.data.items]) : [response.data.items]
							)
						} else {
							let itemVariations = []

							if (this.itemId) {
								itemVariations = await this.$bridge.getItemVariations(this.deviceId, this.objToJson({
									item_id: this.itemId
								}))

								itemVariations = (typeof itemVariations === 'string' ? JSON.parse(itemVariations) : itemVariations).data
								itemVariations = itemVariations.reduce((variations, v) => {
									if (v.id !== this.variationId) {
										variations.push({
											...v,
											unit_measure_type: {
												...v.unit_measure_type,
												custom_attributes: this.objToJson({
													...v.unit_measure_type.custom_attributes
												})
											},
											price_category: v.price_category.map((p) => {
												return {
													...p,
													custom_attributes: this.objToJson({
														...p.custom_attributes
													})
												}
											}),
											custom_attributes: this.objToJson({
												...v.custom_attributes
											})
										})
									}

									return variations
								}, [])
							}

							const newVariation = {
								id: this.variationId || date.valueOf(),
								merchant_id: this.merchant.id,
								device_id: this.deviceId,
								kot_device_id: null,
								item_id: this.itemId || date.valueOf(),
								inventory_id: 0,
								location_id: this.locationId,
								name: this.variationName,
								sku: this.sku,
								sku_generated: this.sku === this.generatedSKU,
								type: this.variationType,
								barcode: this.barcode || '',
								alternate_code: this.alternateCode || '',
								food_type: this.foodType,
								img_url: '',
								unit_measure_type: {
									...this.unitMeasureType,
									custom_attributes: '{}',
									master_id: 1,
									name: this.unitMeasureType.label,
									short_name: this.unitMeasureType.value
								},
								custom_attributes: this.objToJson({
									alternate_language: this.variationAlternateName,
									hsn: '',
									mrp: this.mrp,
									buying_price: this.buyingPrice,
									track_inventory: this.trackInventory,
									is_online_available: this.onlineAvailability,
									online_track_inventory: this.trackOnlineInventory,
									max_price: this.maxPrice || '',
									min_price: this.minPrice
								}),
								description: this.description || '',
								pricing_type: this.pricingType,
								price: +this.price,
								batch: [],
								stock: +this.stock,
								tax: this.selectedTaxes,
								price_category: this.selectedPriceCategories.map((pc, index) => ({
									...pc,
									id: pc.type === 'base' ? date.valueOf() + index : pc.id,
									category_id: pc.category_id || pc.id,
									is_active: false,
									type: 'item',
									custom_attributes: this.objToJson(pc.custom_attributes),
									update_at: date
								})),
								is_available: true,
								is_active: true,
								updated_at: date
							}
							const item = {
								id: this.itemId || date.valueOf(),
								merchant_id: this.merchant.id,
								device_id: this.deviceId,
								category_id: category?.id || null,
								name: this.itemName,
								img_url: '',
								variations: itemVariations.concat(newVariation),
								brand_id: brand?.id || null,
								is_active: true,
								custom_attributes: this.objToJson({
									alternate_language: this.itemAlternateName
								}),
								updated_at: date
							}

							await this.$bridge.insert(
								'Item',
								this.bridgeName === 'ANDROID' ? this.objToJson(item) : item,
								true
							)
						}

						this.$swal({
							title: `${this.$options.filters.capitalize(this.$tc('success', 1))}!`,
							text: this.$t(`item${this.itemId ? 'Updated' : 'Created'}`),
							icon: 'success',
							button: this.$t('ok')
						})
						this.$emit('update:items')
						this.$refs.createItemModal?.hide()
					} else {
						this.$swal({
							title: this.$t('oopsError.title'),
							text: this.$t('oopsError.text'),
							icon: 'error',
							button: this.$t('ok')
						})
					}
				}
			},
			removeButtonLoading () {
				if (this.$refs.submitBtn) {
					this.$refs.submitBtn.disabled = false
					this.$refs.submitBtn.classList.remove('running')
				}
			},
			taxCreationModalCallback () {
				this.selectedTaxes.push({
					...this.taxCreationModal.data
				})

				this.taxCreationModal = {
					show: false,
					data: {}
				}
			},
			async processTaxCreation () {
				const date = new Date()
				const newTax = {
					id: date.valueOf(),
					merchant_id: this.merchant.id,
					device_id: this.deviceId,
					tax_type_id: 1,
					name: this.newTax.name,
					rate: parseFloat(this.newTax.percentage),
					inclusion_type: this.newTax.type,
					updated_at: date

				}

				await this.$bridge.insert(
					'Tax',
					this.bridgeName === 'ANDROID' ? this.objToJson(newTax) : newTax,
					true
				)
				this.selectedTaxes.pop()
				this.selectedTaxes.push(newTax)
				this.$bvModal.hide('create-tax-modal')
			},
			resetModal () {
				this.$store.commit('setState', {
					key: 'item',
					value: { show: false }
				})
				generatedSku = null
			}
		}
	}
