
	import VueBarcode from 'vue-barcode'
	import VueQrcode from '@chenfengyuan/vue-qrcode'

	export default {
		components: {
			VueBarcode,
			VueQrcode
		},
		props: {
			i18n: {
				type: Object,
				required: true
			},
			bridge: {
				type: Object,
				required: true
			},
			moment: {
				type: Function,
				required: true
			},
			currency: {
				type: Object,
				required: true
			},
			domtoimage: {
				type: Object,
				required: true
			},
			data: {
				type: Object,
				required: true
			}
		},
		data () {
			return {
				order: null
			}
		},
		computed: {
			bridgeName () {
				return this.$store.state.bridgeName
			},
			locale () {
				return this.$store.state.locale
			},
			appVersionNumber () {
				return this.$store.getters.appVersionNumber
			},
			partner () {
				return this.$store.state.partner
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			merchant () {
				const parentMerchant = this.$store.state.merchant

				return this.$store.state.settings.receipt.print_child_merchant_details
					? parentMerchant.id === this.data.order.merchant_id
						? parentMerchant
						: parentMerchant.childMerchants?.find(child => child.id === this.data.order.merchant_id) || parentMerchant
					: parentMerchant
			},
			location () {
				return this.$store.state.location
			},
			settings () {
				return this.$store.state.settings
			},
			employee () {
				return this.$store.state.employee
			},
			printerSettings () {
				return this.$store.state.printerSettings
			},
			showActualSubtotal () {
				return (
					!this.order.custom_attributes.tax_calculation_phase ||
					this.order.custom_attributes.tax_calculation_phase === 'before_discount'
				) && this.settings.general.include_tax_in_subtotal
			},
			computedPrice () {
				const price = {
					subtotal: this.order.sub_total,
					tax: this.order.total_tax
				}

				if (this.showActualSubtotal) {
					price.subtotal -= this.order.total_discounted_amount
					price.tax -= this.order.total_discounted_tax
				}

				return price
			},
			amountSavedOnMrp () {
				return this.order.items.reduce((sum, item) => {
					if (item.mrp) {
						sum += (item.mrp - (item.price || item.single_quantity_amount)) * item.quantity
					}

					return sum
				}, 0)
			},
			totalAmount () {
				return this.order.items.reduce((sum, item) => {
					sum += this.currency.transformNumber((item.price || item.single_quantity_amount) * item.quantity)

					return sum
				}, 0)
			}
		},
		beforeMount () {
			const order = JSON.parse(this.objToJson(this.data.order))
			let allRefundedItems = []

			order.refunds = order.refunds || []

			if (order.refund) {
				order.refunds = [{
					...order.refund
				}]
			}

			const orderTotal = this.currency.transformNumber(order.refunds.reduce((sum, refund) => {
				sum += refund.amount

				return sum
			}, 0) + order.tip)

			if (order && order.refunds.length) {
				order.refund_type = orderTotal === this.currency.transformNumber(order.total_price) ? 'full' : 'partial'
			}

			if (order) {
				if (+this.settings.receipt.print_items_alpha_order) {
					order.items.sort((a, b) => {
						const aName = (a.variation_name || a.item_variation_name).toLowerCase()
						const bName = (b.variation_name || b.item_variation_name).toLowerCase()

						return aName > bName ? 1 : (aName < bName ? -1 : 0)
					})
				}

				if (order.refunds && order.refunds.length) {
					if (order.refund_type === 'partial') {
						let orderSubTotal = 0
						let totalTax = 0
						let totalDiscount = 0
						let orderRefundedTaxes = []

						order.refunds.forEach((refund) => {
							orderRefundedTaxes = orderRefundedTaxes.concat(refund.taxes)
						})

						if (order.refunds && order.refunds.length) {
							if (order.refunds && order.refunds.length) {
								order.refunds.forEach((refund) => {
									refund.items.forEach((item) => {
										const orderItem = order.items.find((i) => {
											return i.item_code === item.item_code
										})

										item.variation_name = orderItem.variation_name
										item.alternate_name = orderItem.alternate_name
										item.mrp = orderItem.mrp
										item.price = orderItem.price || orderItem.single_quantity_amount
										item.unit_measure_type = orderItem.unit_measure_type
										item.groups = orderItem.groups
										allRefundedItems.push(item) // All refunded items are pushed in array
									})
								})
							}

							allRefundedItems = Object.values(allRefundedItems.reduce((acc, curr) => { // Unique refuned items are aggregated and added with the quantity refuneded
								if (!acc[curr.item_code]) {
									acc[curr.item_code] = curr
								} else {
									acc[curr.item_code] = {
										...curr,
										refunded_qty: curr.refunded_qty + acc[curr.item_code].refunded_qty,
										refunded_amount: curr.refunded_amount + acc[curr.item_code].refunded_amount,
										refunded_tax: curr.refunded_tax + acc[curr.item_code].refunded_tax
									}
								}

								return acc
							}, {}))

							order.refunds = order.refunds.map((orderRefund) => {
								return {
									...orderRefund,
									items: orderRefund.items.map((refundItem) => {
										const orderItem = order.items.find(i => i.item_code === refundItem.item_code)

										if (orderItem) {
											refundItem = {
												...orderItem,
												...refundItem

											}
										}

										return refundItem
									})
								}
							})

							order.items = order.items.reduce((items, item) => {
								const refundItem = allRefundedItems.find((refundItem) => {
									return item.item_code === refundItem.item_code
								})

								if (refundItem) {
									let totalItemRefundQuantity = 0
									let subTotal = 0

									totalItemRefundQuantity += refundItem.refunded_qty
									subTotal += (
										refundItem.refunded_amount - refundItem.refunded_tax
									) + ((item.discounted_amount / item.quantity) * totalItemRefundQuantity)

									orderSubTotal += subTotal
									totalDiscount += (item.discount / item.quantity) * totalItemRefundQuantity

									if (item.quantity - totalItemRefundQuantity > 0) {
										const itemDiscount = item.discount
										const discountKey = item.discounts ? 'discounts' : item.discount_details ? 'discount_details' : ''

										item.discount = (item.discount / item.quantity) * (item.quantity - totalItemRefundQuantity)

										if (item[discountKey]) {
											item[discountKey] = item[discountKey].map((d) => {
												const totalDiscount = d.discounted_amount + d.discounted_tax
												const computedDiscount = item.discount * (totalDiscount / itemDiscount)

												d.discounted_amount = computedDiscount * (d.discounted_amount / totalDiscount)
												d.discounted_tax = computedDiscount * (d.discounted_tax / totalDiscount)

												return d
											})
										}

										items.push({
											...item,
											quantity: item.quantity - totalItemRefundQuantity,
											sub_total: subTotal
										})
									}
								} else {
									items.push({
										...item
									})
								}

								return items
							}, [])
						}

						orderRefundedTaxes = Object.values(orderRefundedTaxes.reduce((acc, curr) => {
							if (!acc[curr.tax_id]) {
								acc[curr.tax_id] = curr
							} else {
								acc[curr.tax_id] = {
									...curr,
									refunded_tax: acc[curr.tax_id].refunded_tax + curr.refunded_tax,
									discounted_tax: acc[curr.tax_id].discounted_tax + curr.discounted_tax
								}
								acc[curr.tax_id] = curr
							}

							return acc
						}, {}))

						order.total_refund_amount = order.refunds.reduce((sum, refund) => {
							if (refund.type === 'partial') {
								sum += refund.amount
							}

							return sum
						}, 0)
						order.total_price = order.total_price - (order.tip || 0) - (order.refunds.length && order.refund_type !== 'full' ? order.total_refund_amount : 0)
						order.sub_total = order.sub_total - (order.refund_type === 'partial' ? orderSubTotal : 0)
						orderRefundedTaxes = JSON.parse(this.objToJson(orderRefundedTaxes))

						const orderTaxes = {}

						order.taxes.forEach((tax) => {
							orderTaxes[tax.tax_id] = tax
						})

						orderRefundedTaxes.forEach((tax) => {
							orderTaxes[tax.tax_id].tax_amount -= tax.refunded_tax + tax.discounted_tax
						})

						let ot = []

						order.items.forEach((item) => {
							ot = ot.concat(item.taxes.map(t => t.tax_id))
						})

						order.taxes = Object.values(orderTaxes).filter(t => ot.includes(t.tax_id))

						order.taxes.forEach((tax) => {
							totalTax += tax.tax_amount
						})

						order.total_tax = order.refund_type === 'partial' ? totalTax : order.total_tax
						order.total_discount = order.total_discount - (order.refund_type === 'partial' ? totalDiscount : 0)

						if (this.settings.general.round_off_total &&
							order.total_price !== this.data.order.total_price) {
							order.round_off_amount = Math.round(order.total_price) - order.total_price
							order.total_price = Math.round(order.total_price)
						}
					} else {
						order.total_price = order.total_price - (order.tip || 0)
					}
				}

				let refundedTax = 0

				order.taxable_items = order.items.reduce((items, item) => {
					let taxes = []
					const refundItem = allRefundedItems.length
						? allRefundedItems.find((refundItem) => {
							return item.item_code === refundItem.item_code
						})
						: null

					if (refundItem && order.refund_type === 'partial') {
						let refundItemTaxAggregate = []

						order.refunds.forEach((refund) => {
							refund.items.forEach((i) => {
								if (i.item_code === refundItem.item_code) {
									if (i.refunded_taxes.length) {
										refundItemTaxAggregate = refundItemTaxAggregate.concat(i.refunded_taxes)
									}
								}
							})

							return null
						})

						refundItemTaxAggregate = Object.values(refundItemTaxAggregate.reduce((acc, curr) => {
							if (!acc[curr.tax_id]) {
								acc[curr.tax_id] = curr
							} else {
								acc[curr.tax_id] = {
									...curr,
									refunded_tax: acc[curr.tax_id].refunded_tax + curr.refunded_tax,
									discounted_tax: acc[curr.tax_id].discounted_tax + curr.discounted_tax
								}
							}

							return acc
						}, {}))

						refundedTax = refundItemTaxAggregate.reduce((sum, tax) => {
							sum += tax.refunded_tax + tax.discounted_tax

							return sum
						}, 0)

						if (refundItem.refunded_taxes) {
							refundItem.refunded_taxes = refundItemTaxAggregate.map((tax) => {
								return {
									...tax,
									tax_amount: tax.tax_amount - (tax.refunded_tax + tax.discounted_tax)
								}
							})
						}

						taxes = [...(refundItem.refunded_taxes || [])]
						item.tax -= refundedTax
					} else {
						taxes = [...(item.taxes || item.tax_details || [])]
					}

					if (taxes.length > 0) {
						delete (item.taxes || item.tax_details)

						items.push({
							...item,
							taxes
						})
					}

					return items
				}, [])

				order.items = JSON.parse(this.objToJson(order.items))

				if (order.refunds.length && order.custom_attributes && order.custom_attributes.loyalty_points && (order.custom_attributes.loyalty_points.redeemable_points || order.custom_attributes.loyalty_points.earned_points)) {
					let totalDiscountRefunded = 0
					let refundedAmount = 0
					const signupPoints = (order.custom_attributes.loyalty_points.earned_points - +Math.floor(order.total_amount / order.custom_attributes.loyalty_points.spendingPerPoint))

					order.refunds.forEach((refund) => {
						refundedAmount += refund.amount
						refund.items.forEach((refundItem) => {
							const refundLoyaltyDiscountIndex = refundItem.discounts ? refundItem.discounts.findIndex(discount => discount.name === 'Loyalty') : -1

							if (refundLoyaltyDiscountIndex !== -1) {
								totalDiscountRefunded += (refundItem.discounts[refundLoyaltyDiscountIndex].get_discount_value - refundItem.discounts[refundLoyaltyDiscountIndex].discounted_amount + refundItem.discounts[refundLoyaltyDiscountIndex].discounted_tax)
							}
						})
					})
					order.custom_attributes.loyalty_points.redeemable_points = totalDiscountRefunded ? (order.custom_attributes.loyalty_points.redeemable_points - Math.ceil(totalDiscountRefunded / order.custom_attributes.loyalty_points.discountedAmountPerPoint)) : 0
					order.custom_attributes.loyalty_points.earned_points = Math.floor((order.total_amount - this.currency.transformNumber(refundedAmount)) / order.custom_attributes.loyalty_points.spendingPerPoint) + signupPoints
				}

				order.discounted_items = order.items.reduce((items, item) => {
					const discounts = [...(item.discounts || item.discount_details || [])]

					if (discounts.length > 0) {
						delete (item.discounts || item.discount_details)

						items.push({
							...item,
							discounts
						})
					}

					return items
				}, [])

				order.refundedItems = allRefundedItems
				this.order = order
			}
		},
		methods: {
			renderReceipt () {
				const iframe = document.getElementById(this.data.id)
				const iframeDocument = iframe.contentDocument
				const el = iframeDocument.getElementById('receipt')
				const height = el.offsetHeight * this.printerSettings.scaleFactor
				const width = el.offsetWidth * this.printerSettings.scaleFactor

				this.domtoimage.toPng(el, {
					height,
					width
				}).then(async (dataURL) => {
					if (process.env.NODE_ENV !== 'production') {
						// eslint-disable-next-line
						console.log('%c ', `font-size: 1px; padding: ${Math.floor(height / 2)}px ${Math.floor(width / 2)}px; background: url(${dataURL})`)
					}

					const printerSettings = Object.assign({}, this.printerSettings)

					if (this.data.order.printerInterface) {
						printerSettings.interface = this.data.order.printerInterface
					}

					const printData = {
						dataURL,
						fileName: `${this.data.id}.png`,
						printerSettings,
						order: this.data.order
					}

					if (
						this.settings.receipt.print_qr_footer_message &&
						this.bridgeName === 'ANDROID' &&
						(this.printerSettings.interface === 'usb' || this.printerSettings.interface === 'bluetooth') &&
						this.printerSettings.qrPrintMode === 1
					) {
						el.querySelector('#footer-qr-message')?.remove()
						printData.dataURLWoQr = await this.domtoimage.toPng(el, { // dataURL without QR Code
							height,
							width
						})
						printData.qrFooterMessage = this.settings.receipt.qr_footer_message_text
					}

					if (this.data.type === 'download') {
						const options = {
							fileName: printData.fileName,
							dataURL: dataURL.replace(/^data:image\/png;base64,/, '')
						}

						this.bridge.downloadFile(
							this.bridgeName === 'ANDROID' ? this.objToJson(options) : options
						)
					} else if (+this.settings.receipt.preview && !this.order.waiter) {
						this.$store.commit('setState', {
							key: 'receiptPreview',
							value: printData
						})
					} else {
						this.bridge.pushPrinterQueue(this.bridgeName === 'ANDROID' ? this.objToJson({ ...printData, dataURL: (printData.dataURLWoQr || printData.dataURL) }) : printData)
					}

					if (process.env.NODE_ENV === 'production' && this.data.type !== 'download') {
						this.$store.dispatch('uploadToS3', {
							type: 'image',
							merchantId: this.merchant.id,
							locationId: this.location.id,
							key: `orders/${this.order.receipt_code}`,
							dataURL
						}).catch(console.error)
					}
				}).catch(console.error).finally(() => {
					this.$emit('destroy')
				})
			}
		}
	}
